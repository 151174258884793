import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ENGOO_DEFAULT, getLocale, MARKET_LIST } from '../constants/Countries';
import I18nService from '../services/I18nService';

const I18nContext = createContext();

export const useI18n = () => useContext(I18nContext);

const i18nService = new I18nService();

export const I18nProvider = ({
  children,
  region = ENGOO_DEFAULT,
  service = i18nService
}) => {
  const [i18n, setI18n] = useState(() => {
    service.setLocale(getLocale[region]);
    return makeI18nContext(service);
  });

  useEffect(() => {
    i18n.setLocale(getLocale[region]);
  }, [region, i18n]);

  useEffect(() => {
    const onLocalizationChange = () => {
      setI18n(makeI18nContext(service));
    };

    const unsubscribe = service.subscribe(onLocalizationChange);

    return () => {
      unsubscribe();
    };
  }, [service]);

  return (
    <I18nContext.Provider value={i18n}>
      {children}
    </I18nContext.Provider>
  );
};

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
  region: PropTypes.oneOf(MARKET_LIST),
  service: PropTypes.instanceOf(I18nService),
};

function makeI18nContext(service) {
  return {
    locale: service.getLocale(),
    setLocale: (...args) => service.setLocale(...args),
    // eslint-disable-next-line @engoo/engoo/literal-string-keys
    t: (...args) => service.t(...args),
  };
}

export default I18nProvider;
